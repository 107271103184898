import { palette as p, yFormat } from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';
import React from 'react';
import getStatCanData from '../StatCanApi.js';

async function getSettingsAfterDataLoad() {
  const prodData = await getStatCanData('DF_25100063', '1.2.1', '2000-01', '2022-12');
  const expData = await getStatCanData('DF_25100063', '1.24.1', '2000-01', '2022-12');

  const settings = getDefaultChartSettings();
  settings.xMin = '2016-01'
  settings.yMin = 10_000_000;
  settings.yMax = 26_000_000;
  settings.title = 'Canada crude oil production (m3)';
  settings.labels = prodData.label
  settings.datasets = [
    ['Crude oil production', prodData.values, p.red],
    ['Exports', expData.values, p.blue]
  ];
  return settings;
}

export default class OilChart extends React.Component {
  constructor(props){
    super(props);
    this.state = {settings: getDefaultChartSettings()};
  }

  componentDidMount() {
    getSettingsAfterDataLoad().then(settings => this.setState({settings: settings}));
  }

  render() {
    return <TimeChart settings={this.state.settings} />;
  }
}