import { palette as p } from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';


const data = require('./cpi.json');
const settings = getDefaultChartSettings();
settings.yMin = -2;
settings.yMax = 12;
settings.title = 'Consumer Price Index for Canada, US, and OECD (12 month change)';
settings.labels = data.label
settings.datasets = [
  ['Canada CPI', data.canadaCpi, p.economistRed],
  ['Canada CPI (excluding food and energy)', data.canadaCpi2, p.salmon],
  ['US CPI', data.usCpi, p.blue],
  ['OECD CPI', data.oecd, p.brown]
];

export default function CpiChart() {
  return <TimeChart settings={settings}/>;
}