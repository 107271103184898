import { enCA } from 'date-fns/locale';
import { palette, timeUnit, yFormat, mFormat, qFormat } from './Constants';


export function getDefaultChartSettings() {
  return {
    ar: 2,
    xUnit: timeUnit.Y,
    xMinUnit: timeUnit.M,
    yFormat: yFormat.Y4,
    mFormat: mFormat.M4,
    qFormat: qFormat.Q3,
    xbColour: palette.london20,
    xlColour: palette.london20,
    xbDisplay: true,
    xlDisplay: false,
    ybColour: palette.london10,
    ylColour: palette.london85,
    ybDisplay: false,
    ylDisplay: true,
    fColour: palette.london10,
    xMin: '2018-01-01',
    xMax: '2023-01-01',
    yMin: 0,
    yMax: 10,
    bWidth: 1.25,
    glWidth: 1.5,
    pRadius: 0,
    lWidth: 2.5,
    labelRotation: 0,
    tfSize: 14,
    title: '',
    legendDisplay: true,
    legendPosition: 'top',
    labels: null,
    datasets: []
  }
}

export function getChartJsOptions(settings) {
  if(!settings.xlDisplay) {
    settings.xlColour = '';
  }

  return {
    responsive: true,
    aspectRatio: settings.ar,                     // width / height ratio
    maintainAspectRatio: true,
    scales: {
      x: {
        type: 'time',
        min: settings.xMin,
        max: settings.xMax,
        time: {
          unit: settings.xUnit,
          minUnit: settings.xMinUnit,
          displayFormats: {
            year: settings.yFormat,
            month: settings.mFormat,
            quarter: settings.qFormat
          }
        },
        grid: {
          display: true,                // has to be true to see ticks
          drawBorder: settings.xbDisplay,
          drawTicks: settings.xbDisplay,
          borderWidth: settings.bWidth,     // axis line width
          lineWidth: settings.glWidth,
          borderColor: settings.xbColour,   // axis line color
          tickColor: settings.xbColour,     // axis tick color
          color: settings.xlColour          // removed color to not see the grid lines
        },
        ticks: {
          display: true,
          color: settings.xbColour,             // axis label color
          maxRotation: settings.labelRotation,  // prevent slanted display
          align: 'center',
          crossAlign: 'near'
        },
        adapters: {
          date: {
            locale: enCA
          }
        }
      },
      y: {
        min: settings.yMin,
        max: settings.yMax,
        grid: {
          display: true,
          drawBorder: settings.ybDisplay,
          drawTicks: settings.ybDisplay,
          borderWidth: settings.bWidth,
          lineWidth: settings.glWidth,
          borderColor: settings.ybColour,   // axis line color
          tickColor: settings.ybColour,     // axis tick color
          color: settings.ylColour
        },
        ticks: {
          color: settings.ybColour,      // axis label color
          align: 'end',
          crossAlign: 'near'
        },
        position: 'right'
      }
    },
    elements: {
      point: {
        radius: settings.pRadius
      },

      line: {
        tension: 0,
        borderWidth: settings.lWidth
      }
    },
    plugins: {
      title: {
        display: true,
        align: 'start',
        color: settings.fColour,
        text: settings.title,
        font: {
          size: settings.tfSize
        }
      },
      legend: {
        display: settings.legendDisplay,
        position: settings.legendPosition,
        labels: {
          boxWidth: 10
        }
      }
    },
  };
}

export function getChartJsData(settings) {
  return {
    labels: settings.labels,
    datasets: settings.datasets.map(e => {
      return {
        label: e[0],
        data: e[1],
        borderColor: e[2],
        backgroundColor: e[2]
      }
    })
  };
}

