export const palette = {

    red: '#d9585c',
    blue: '#006ca2',
    cyan: '#31c0d0',
    brown: '#aeaca1',
    mustard: '#eca220',
    salmon: '#f79375',
    red2: '#ee422b',
  
    economistRed: '#E3120B',
    economistRed60: '#F6423C',
  
    chicago20: '#141F52',
    chicago30: '#1F2E7A',
    chicago45: '#2E45B8',
    chicago55: '#475ED1',
    chicago90: '#D6DBF5',
    chicago95: '#EBEDFA',
  
    hongKong45: '#1DC9A4',
    hongKong55: '#36E2BD',
  
    singapore55: '#F97A1F',
    singapore65: '#FB9851',
  
    london5: '#0D0D0D',
    london10: '#1A1A1A',
    london20: '#333333',
    london35: '#595959',
    london70: '#B3B3B3',
    london85: '#D9D9D9'
  
  }

export const fonts = {
  arvo: 'arvo',
  merri: 'merriweather',
  pt: 'pt-serif',
  lora: 'lora',
  nanum: 'nanum',
  playfair: 'playfair'
}

export const timeUnit = {
  W: 'week',
  M: 'month',
  Q: 'quarter',
  Y: 'year'
}

//https://date-fns.org/v2.29.3/docs/format

export const yFormat = {
  Y1: 'yy',   // 00, 17
  Y4: 'yyyy'  // 1900, 2017
}

export const mFormat = {
  M1: 'M',    // 1, 2, ..., 12
  M3: 'MMM',  // Jan, Feb, ... Dec
  M4: 'MMMM', // January, February, ... December
  M5: 'MMMMM' // J, F, ..., D
}

export const qFormat = {
  Q1: 'M yy',
  Q2: 'MMM yy',
  Q3: 'MMMMM yy'
}