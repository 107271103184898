import React from 'react';

import { defaults } from 'chart.js';
import './App.css';
import { fonts, palette } from './charts/Constants.js';
import CpiChart from './charts/cpi/CpiChart';
import EmploymentChart from './charts/employment/Employment';
import InterestChart from './charts/interest/InterestChart';
import './index.css';
import TradeChart from './charts/trade/Trade';
import DebtChart from './charts/debt/Debt';
import OilChart from './charts/oil/OilChart';

defaults.font.family = fonts.lora;

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{margin: 'auto', textAlign: 'center', maxWidth: '800px', color: palette.london10 }}>
        <div style={{ fontFamily: fonts.lora }}>
          <h1 style={{ margin: 'auto', padding: '40px' }}>Smart Citizens</h1>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px'}}>
            <CpiChart/>
          </div>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px' }}>
            <InterestChart/>
          </div>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px' }}>
            <EmploymentChart/>
          </div>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px' }}>
            <TradeChart/>
          </div>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px' }}>
            <DebtChart/>
          </div>
          <div style={{ maxWidth: '600px', margin:'auto', padding: '50px' }}>
            <OilChart/>
          </div>
        </div>
      </div>
    );
  }

}


export default App;