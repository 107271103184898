
export default function getStatCanData(dataflow, dimensions, startPeriod, endPeriod) {
  const baseUrl = new URL('https://www150.statcan.gc.ca/t1/wds/sdmx/statcan/v1/rest/data/')
  const url = new URL(`${dataflow}/${dimensions}`, baseUrl);
  url.searchParams.set('startPeriod', startPeriod);
  url.searchParams.set('endPeriod', endPeriod);
  return fetch(url, {
    method: 'GET',
    headers: { 'Accept': 'application/vnd.sdmx.data+json;version=1.0.0-wd' }
  })
    .then(response => response.json())
    .then(data => {
      const seriesKey = Array(data['structure']['dimensions']['series'].length).fill(0).join(':');
      const valueSeries = Object.entries(data['dataSets'][0]['series'][seriesKey]['observations'])
        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
        .map(e => e[1][0]);
      const timeSeries = data['structure']['dimensions']['observation'][0]['values'].map(e => e['end']);

      return { label: timeSeries, values: valueSeries }
    });
}