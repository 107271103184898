import { palette as p } from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';
import React from 'react';
import getStatCanData from '../StatCanApi.js';

async function getSettingsAfterDataLoad() {
  const govtData = await getStatCanData('DF_38100237', '1.1', '2000-Q1', '2022-Q4');
  const householdData = await getStatCanData('DF_38100235', '1.1.1', '2000-Q1', '2022-Q4');

  const settings = getDefaultChartSettings();
  settings.xMin = '2000-01'
  settings.yMin = 50;
  settings.yMax = 150;
  settings.title = 'Canada household and government debt to GDP';
  settings.labels = householdData.label
  settings.datasets = [
    ['Govt debt to GDP', govtData.values, p.economistRed],
    ['Household debt to GDP', householdData.values, p.salmon]
  ];
  return settings;
}

export default class DebtChart extends React.Component {
  constructor(props){
    super(props);
    this.state = {settings: getDefaultChartSettings()};
  }

  componentDidMount() {
    getSettingsAfterDataLoad().then(settings => this.setState({settings: settings}));
  }

  render() {
    return <TimeChart settings={this.state.settings} />;
  }
}