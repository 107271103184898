import { palette as p, yFormat } from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';
import React from 'react';
import getStatCanData from '../StatCanApi.js';

async function getSettingsAfterDataLoad() {
  //const data = await getStatCanData('DF_14100017', '1.8.1.1', '2000-01', '2022-12');
  const data = await getStatCanData('DF_14100287', '1.7.1.1.1.1', '2000-01', '2022-12');

  const settings = getDefaultChartSettings();
  settings.yFormat = yFormat.Y4
  settings.yMin = 0;
  settings.yMax = 16;
  settings.title = 'Canada unemployment rate';
  settings.legendDisplay = false
  settings.labels = data.label
  settings.datasets = [['Unemployment Rate', data.values, p.economistRed]];
  return settings;
}

export default class EmploymentChart extends React.Component {
  constructor(props){
    super(props);
    this.state = {settings: getDefaultChartSettings()};
  }

  componentDidMount() {
    getSettingsAfterDataLoad().then(settings => this.setState({settings: settings}));
  }

  render() {
    return <TimeChart settings={this.state.settings} />;
  }
}