import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import { getChartJsOptions, getChartJsData } from './ChartSettings';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function TimeChart(props) {
  return <Line options={getChartJsOptions(props.settings)} data={getChartJsData(props.settings)} />;
}