import { palette as p} from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';


const data = require('./interest.json');
const settings = getDefaultChartSettings();
settings.yMin = 0;
settings.yMax = 6;
settings.xMin = '2013-01-01';
settings.title = 'Bank of Canada Target Overnight Rate';
settings.legendDisplay = false;
settings.labels = data.label
settings.datasets = [['Target Overnight Rate', data.nightTarget, p.economistRed]];


export default function InterestChart(props) {
  return <TimeChart settings= {settings}/>;
}