import { palette as p, yFormat } from '../Constants.js';
import { getDefaultChartSettings } from '../ChartSettings';
import TimeChart from '../TimeChart.js';
import React from 'react';
import getStatCanData from '../StatCanApi.js';

async function getSettingsAfterDataLoad() {
  const importData = await getStatCanData('DF_12100011', '1.1.2.2.1', '2000-01', '2022-12');
  const exportData = await getStatCanData('DF_12100011', '1.2.2.2.1', '2000-01', '2022-12');
  const usData = await getStatCanData('DF_12100011', '1.2.2.2.2', '2000-01', '2022-12');
  const euData = await getStatCanData('DF_12100011', '1.2.2.2.3', '2000-01', '2022-12');
  const chData = await getStatCanData('DF_12100011', '1.2.2.2.11', '2000-01', '2022-12');

  const settings = getDefaultChartSettings();
  settings.yMin = 0;
  settings.yMax = 80_000;
  settings.title = 'Canada trade exports/imports to all nations (Millions $)';
  settings.legendDisplay = true
  settings.labels = exportData.label
  settings.datasets = [
    ['Export', exportData.values, p.chicago20],
    ['Import', importData.values, p.blue],
    ['Exports to US', usData.values, p.cyan],
    ['Exports to EU', euData.values, p.hongKong55],
    ['Exports to China', chData.values, p.economistRed],
  ];
  return settings;
}

export default class TradeChart extends React.Component {
  constructor(props){
    super(props);
    this.state = {settings: getDefaultChartSettings()};
  }

  componentDidMount() {
    getSettingsAfterDataLoad().then(settings => this.setState({settings: settings}));
  }

  render() {
    return <TimeChart settings={this.state.settings} />;
  }
}